.App {
  text-align: center;
}

.App-logo {
  height: 50px;
    width: 50px;
    /* margin-bottom: -6px; */
    pointer-events: none;
    position: absolute;
    top: 7px;
    left:  3px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
 

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* 隐藏默认的箭头 */
.ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  display: none;
}





@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.right-align {
  display: flex;
  justify-content: space-between;
}


.footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  height: 6dvh;
  min-height: 50px;
  width: 100%;
  font-size: 9px;
  padding: 0px 0px 9px 0px;
}

@media screen and (min-width: 576px) {
  .footer {
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;
    font-size: 12px;
    min-height: 50px;
    padding: 24px 0px 14px 0px;
  }
}

.content {
  min-height: 100vh;
}

@media screen and (min-width: 576px) {
  .content {
    height: 90vh;
  }
}

::-webkit-scrollbar {
  width: 0;
  /* 垂直滚动条的宽度 */
  height: auto;
  /* 水平滚动条的高度 */

}